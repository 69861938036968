<template>
    <transition appear enter-active-class="animated fadeIn">
        <div>
            <div class="level-item">
                <h1 class="title is-1 page-title">MAKING A CLAIM</h1>
            </div>
            <div class="box text-center b-shdw-3">
                <h5>
                    MILLSTREAM CLAIMS
                    <br>
                    FOR POLICIES UNDERWRITTEN BY MILLSTEAM / ISSUED ON OR AFTER 14 October 2021
                </h5>
                <p>
                    <b>How to make a travel insurance claim</b>
                </p>
                <br>
                <p>
                    The quickest and easiest way to make a claim on your Covered2Go Travel insurance is online.
                </p>
                <br>
                <div class="list-items">
                    <ul>
                        <li>Submit a claim online at any time</li>
                        <li>Upload your supporting documents</li>
                        <li>Save at any point and complete your claim later</li>
                    </ul>
                </div>
                <br>
                <p>
                    <a href="https://www.submitaclaim.co.uk/C2GO" target="_blank">Click Here to Make a Claim Online</a>
                </p>
                <br>
                <p>
                    Alternatively, please contact the Claims Service for a claim form by visiting <a href="https://www.csal.co.uk" target="_blank">www.csal.co.uk</a> or by email on <a href="mailto:claims@mstream.co.uk">claims@mstream.co.uk</a> or by phone on <b>0330 660 0785</b>.
                </p>
                <br>
                <p>
                    <b>Check you are covered before you claim</b>
                </p>
                <br>
                <p>
                    It's important to check that you're covered by your policy before you make a claim. Please read the relevant sections of the policy wording, terms, conditions, and exclusions carefully. Click here for <a href="https://covered2go.co.uk/policy-wordings-mul" target="_blank">Policy Wordings</a>.
                </p>
            </div>

            <br>

            <div class="box text-center b-shdw-3">
                <h5>
                    TIFGROUP CLAIMS
                    <br>
                    FOR POLICIES UNDERWRITTEN BY TIFGROUP / ISSUED BEFORE 7 JULY 2021
                </h5>

                <p>
                    Contact the Claims Department for a claim form by visiting
                    <a href="https://www.policyholderclaims.co.uk/customer/claims/" target="_blank">Policy Holder Claims</a>.
                </p>

                <p><br></p>

                <p>
                    Alternatively, please advise the section of the insurance on which you want to claim and master policy
                    number and policy reference to:
                    <br>
                    The Claims Department, 1 Tower View, Kings Hill, West Malling, Kent, ME19 4UY
                </p>

                <p><br></p>

                <p>
                    Telephone: <strong>+44 (0) 203 829 6761</strong>
                </p>

                <p><br></p>

                <p>
                    Open 8am-8pm Monday-Friday, 9am-1pm Saturday.
                </p>
            </div>

            <br>

            <div class="box text-center b-shdw-3">
                <h5>
                    WHITEHORSE CLAIMS
                    <br>
                    FOR POLICIES UNDERWRITTEN BY WHITEHORSE ISSUED BETWEEN 7 JULY 2021 AND 13 JULY 2021
                </h5>

                <p>
                    Contact the Claims Department for a claim form by visiting
                    <a href="https://www.policyholderclaims.co.uk/customer/claims/" target="_blank">Policy Holder Claims</a> or
                    by phone <strong>0203 829 6603</strong>.
                </p>

            </div>
        </div>
    </transition>
</template>

<script>
    import { contentAPI } from "@/mixins/content-api";
    import { page } from "@/mixins/page";

    export default {
        name: "Claim",
        mixins: [ contentAPI, page ],
    }
</script>

<style lang="scss" scoped>
    h5 {
        color: $c2g_orange;
        margin-bottom: 8px !important;
        text-align: center;
        border-bottom: 3px $c2g_orange solid;
        padding-bottom: 8px;
    }
    .level-item {
        padding: 20px 0 40px 0;
    }

    .list-items{
        width: 450px;
        text-align: left;
        margin: 0 auto;
        padding-left: 50px;
    }

    li {
        list-style: disc;
    }
</style>